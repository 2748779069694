import React from 'react'
import { RunsListQuery } from '~/graphql-codegen-operations.gen'
import { Tooltip } from '@frontend/design-system'
import styles from './module.IntelligenceScoresPill.scss'
import { A11yMiniIcon, UiCoverageMiniIcon } from '../intelligence-utils/icons'
import { Link } from '@reach/router'
import { intelligenceVariants } from '../intelligence-utils/util'
import { getIntelligenceRunSummaryUi } from '../intelligence-utils/getIntelligenceRunSummaryUi'
import { uiCoverageDefaultPath } from '../../uiCoverageNavigation'

export type RunData = RunsListQuery['project']['runs']['nodes'][number]

type IntelligenceScoresPillProps = {
  run: RunData
  basePath: string
}

export const IntelligenceScoresPill: React.FC<IntelligenceScoresPillProps> = ({
  run,
  basePath,
}) => {
  const pills = intelligenceVariants
    .map((variant) => {
      const { intelligenceScoresPill: pill } = getIntelligenceRunSummaryUi(
        variant,
        run
      )
      if (pill) {
        const viewTypePath =
          variant === 'ui-coverage' ? uiCoverageDefaultPath : variant
        return (
          <Tooltip placement="top" overlay={() => pill.popper} key={variant}>
            <Link
              to={`${basePath}/${viewTypePath}`}
              className={styles.section}
              data-cy={pill.dataCy}
            >
              {variant === 'accessibility' ? (
                <A11yMiniIcon />
              ) : (
                <UiCoverageMiniIcon />
              )}
              {pill.value}
            </Link>
          </Tooltip>
        )
      }
      return null
    })
    .filter(Boolean)

  if (pills.length) {
    return (
      <div className={styles.container} data-cy="intelligence-score-pill">
        {pills}
      </div>
    )
  }

  return null
}
