import React, { useEffect, useState } from 'react'

import {
  ProjectOrder,
  SidebarProjectQuery,
} from '~/graphql-codegen-operations.gen'

import { OrgNav } from './org-nav'
import { OrganizationSwitcher } from './organization-switcher'
import { ProjectNav } from './project-nav'
import { CurrentProject } from './types'
import OnboardingChecklist from '~/navs/OnboardingChecklist'
import { features } from '~/lib/feature-flags'
import cs from 'clsx'

export interface SidebarContentProps {
  userData: SidebarProjectQuery['me']
  projectId?: string
  currentProject?: CurrentProject
  currentOrg: SidebarProjectQuery['project']['organizationInfo'] | null
  orderProjectsBy: ProjectOrder
}

export const SidebarContent: React.FunctionComponent<SidebarContentProps> = (
  props
) => {
  const [onboardingUi, setOnboardingUi] = useState(
    'none' as 'none' | 'old' | 'new'
  )

  /**
   * Onboarding UI (new or old) are controlled by the 'onboarding-checklist' flag. If that's enabled, we will show
   * the "new" onboarding UI (progress bar) if the feature flag 'updated-onboarding-ui' is also enabled. Otherwise, we show
   * the "old" onboarding UI (checklist).
   */
  useEffect(() => {
    if (
      !features.isEnabled('onboarding-checklist') ||
      !props.currentOrg?.viewerIsOwnerOrAdmin
    ) {
      setOnboardingUi('none')
    } else if (features.isEnabled('onboarding-checklist') && props.currentOrg) {
      if (features.isEnabled('updated-onboarding-ui')) {
        /**
         * Only show the progress bar onboarding UI when the current org actually has access to the full cloud trial; otherwise,
         * no onboarding UI is shown
         */
        if (props.currentOrg.hasFullCloudTrial) {
          setOnboardingUi('new')
        } else {
          setOnboardingUi('none')
        }
      } else {
        setOnboardingUi('old')
      }
    }
  }, [props.currentOrg])

  const pickSpacingClass = () => {
    switch (onboardingUi) {
      case 'none': {
        return 'no-onboarding'
      }
      case 'new':
      case 'old':
      default: {
        return 'showing-onboarding'
      }
    }
  }

  return (
    <>
      <OrganizationSwitcher
        userData={props.userData}
        currentOrg={props.currentOrg}
      />
      {/* Must still show an empty div when not showing onboarding UI so the footer stays fixed to the bottom (grid layout) */}
      {onboardingUi === 'old' ? (
        <OnboardingChecklist
          organization={props.currentOrg}
          project={props.currentProject}
        />
      ) : (
        <div />
      )}
      <div
        className={cs(
          'nav-switcher',
          props.projectId ? 'project' : 'org',
          pickSpacingClass()
        )}
      >
        <OrgNav
          // TODO consolidate typings between 2 queries
          organization={props.currentOrg as any}
          useNewOnboarding={onboardingUi === 'new'}
        />
        {props.currentProject && props.currentOrg ? (
          <ProjectNav
            currentOrg={props.currentOrg}
            currentProject={props.currentProject}
            isActiveView={!!props.projectId}
          />
        ) : (
          <div className="nav-container" />
        )}
      </div>
    </>
  )
}
