import { RouteComponentProps } from '@reach/router'
import React, { ReactNode, useEffect, useMemo } from 'react'
import cs from 'clsx'

import {
  RunContainerRunFragment,
  useErrorsOverviewLazyQuery,
} from '~/graphql-codegen-operations.gen'
import { features } from '~/lib/feature-flags'
import { runStatusEnumToStatus } from '~/lib/utils-ts'
import { ArchiveRunButton } from './ArchiveRunButton'
import { StackedLayoutTabBar } from '~/common/stacked-layout/stacked-layout-tabs/StackedLayoutTabBar'
import { RunTab } from './RunTab'
import { getProperNoun } from '~/common/intelligence/intelligence-utils/formatting'
import { getIntelligenceRunSummaryUi } from '~/common/intelligence/intelligence-utils/getIntelligenceRunSummaryUi'
import { uiCoverageDefaultPath } from '~/common/uiCoverageNavigation'

interface RunTabsProps
  extends RouteComponentProps<{ runId: string; projectId: string }> {
  run: RunContainerRunFragment
  isMember?: boolean
  projectId: string
  beyondDataRetention?: boolean
  className?: string
}

interface RunTabItemProps {
  path: string
  title: string
  url: string
  badge: number | string | ReactNode
  runIds: [string, number]
}

export const RunTabs: React.FC<RunTabsProps> = ({
  run,
  beyondDataRetention,
  isMember = false,
  location,
  projectId,
  className = '',
}) => {
  const runOverviewAnalytics = features.isEnabled('run-overview-analytics')

  const accessibility = getIntelligenceRunSummaryUi('accessibility', run)
  const uiCoverage = getIntelligenceRunSummaryUi('ui-coverage', run)

  const [fetchErrorsData, { data: errorsData }] = useErrorsOverviewLazyQuery({
    variables: {
      perPage: 20,
      runId: run.id,
    },
  })

  const totalErrorCount = errorsData?.run.mostCommonErrors.totalCount || 0

  const tabList = useMemo(() => {
    const _formatURL = (path: string) =>
      `/projects/${run.project.id}/runs/${run.buildNumber}/${path}`

    let specsCount: number | null = run.instances.totalCount
    let testResultsCount: number | null = run.totalTests

    if (!run.specIsolation) {
      specsCount = null
    }

    if (
      beyondDataRetention ||
      runStatusEnumToStatus(run.status) === 'overLimit'
    ) {
      specsCount = null
      testResultsCount = null
    }
    const tabs: RunTabItemProps[] = [
      {
        path: 'overview',
        title: 'Overview',
        url: _formatURL('overview'),
        badge: null,
        runIds: [run.id, run.buildNumber],
      },
      {
        path: 'test-results',
        title: 'Test Results',
        url: _formatURL('test-results'),
        badge: testResultsCount,
        runIds: [run.id, run.buildNumber],
      },
      {
        path: 'specs',
        title: 'Specs',
        url: _formatURL('specs'),
        badge: specsCount,
        runIds: [run.id, run.buildNumber],
      },
    ]

    if (runOverviewAnalytics) {
      tabs.push({
        path: 'errors',
        title: 'Errors',
        url: _formatURL('errors'),
        badge: totalErrorCount,
        runIds: [run.id, run.buildNumber],
      })
    }

    if (uiCoverage.runTab) {
      tabs.push({
        path: 'ui-coverage',
        title: getProperNoun('ui-coverage'),
        url: _formatURL(uiCoverageDefaultPath),
        badge: uiCoverage.runTab,
        runIds: [run.id, run.buildNumber],
      })
    }

    if (accessibility.runTab) {
      tabs.push({
        path: 'accessibility',
        title: getProperNoun('accessibility', { short: true }),
        url: _formatURL('accessibility'),
        badge: accessibility.runTab,
        runIds: [run.id, run.buildNumber],
      })
    }

    tabs.push({
      path: 'properties',
      title: 'Properties',
      url: _formatURL('properties'),
      badge: null,
      runIds: [run.id, run.buildNumber],
    })

    return tabs
  }, [
    run,
    beyondDataRetention,
    totalErrorCount,
    runOverviewAnalytics,
    uiCoverage.runTab,
    accessibility.runTab,
  ])

  useEffect(() => {
    if (runOverviewAnalytics) {
      fetchErrorsData()
    }
  }, [runOverviewAnalytics, fetchErrorsData])

  return (
    <StackedLayoutTabBar className={cs('run-tabs', className)}>
      {tabList.map((tab) => {
        return (
          <RunTab
            key={tab.path}
            url={tab.url}
            path={tab.path}
            title={tab.title}
            badge={tab.badge}
            runIds={tab.runIds}
            projectId={projectId}
            location={location}
          />
        )
      })}
      {isMember &&
        run.status !== 'RUNNING' &&
        run.status !== 'CANCELLED' &&
        run.status !== 'NOTESTS' &&
        !run.archivedAt && (
          <ArchiveRunButton runId={run.id} buildNumber={run.buildNumber} />
        )}
    </StackedLayoutTabBar>
  )
}
