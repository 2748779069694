import React, { useEffect, useState } from 'react'
import { useOrganizationOnboardingQuery } from '~/graphql-codegen-operations.gen'
import { CurrentProject } from './types'
import styles from './module.OnboardingProgressBar.scss'
import { CustomIcon, ProgressBar } from '@frontend/design-system'
import cs from 'clsx'
import { allLevels } from '../onboarding/OnboardingLevels'
import { OnboardingChecklistStatus } from '@packages/common'
import { filter } from 'lodash'
import { differenceInDays } from 'date-fns'

interface ProgressBarProps {
  organizationId: string
  project?: CurrentProject
}

const OnboardingProgressBar: React.FC<ProgressBarProps> = ({
  organizationId,
}) => {
  const { data, loading } = useOrganizationOnboardingQuery({
    variables: {
      orgId: organizationId,
    },
  })

  const [totalSteps, setTotalSteps] = useState(0)
  const [completedSteps, setCompletedSteps] = useState(0)
  const [remainingDays, setRemainingDays] = useState(0)

  useEffect(() => {
    if (data?.organization?.onboardingChecklist) {
      const updatedRows = allLevels
        .map((r) => {
          const rowStatus =
            data.organization.onboardingChecklist?.[
              r.trackingKey
            ]?.toLowerCase()

          if ((data.organization.subscription.planPrice ?? 0) > 0) {
            if (
              r.shouldHideForPaidPlans ||
              rowStatus === OnboardingChecklistStatus.COMPLETED_BEFORE_TRIAL
            ) {
              return null
            }
          }

          const isCompleted = [
            OnboardingChecklistStatus.COMPLETED,
            OnboardingChecklistStatus.SKIPPED,
          ].includes(rowStatus)

          return {
            ...r,
            isCompleted,
          }
        })
        .filter((r) => Boolean(r))

      const completedSteps = filter(updatedRows, (r) => r?.isCompleted)
      const totalCompletedSteps = completedSteps.length
      const totalSteps = updatedRows.length
      setCompletedSteps(totalCompletedSteps)
      setTotalSteps(totalSteps)
    }
  }, [
    data?.organization?.onboardingChecklist,
    data?.organization.subscription.planPrice,
  ])

  useEffect(() => {
    if (!data?.organization.hasFullCloudTrial) {
      return
    }

    const endAt =
      data?.organization.subscription.trialEndAt ??
      data?.organization.subscription.currentTermEndAt

    if (endAt) {
      let remainingDays = differenceInDays(new Date(endAt), new Date())

      if (remainingDays < 0) {
        remainingDays = 0
      }

      setRemainingDays(remainingDays)
    }
  }, [
    data?.organization.subscription.currentTermEndAt,
    data?.organization.subscription.trialEndAt,
  ])

  return (
    <div className={styles.container} data-cy="cloud-trial-progress-bar">
      <CustomIcon name="trial-check-icon" size="x24" className={styles.icon} />
      <div>
        <div className={styles.labelWrapper}>
          <span>Cypress Cloud Trial</span>
          {!loading && (
            <div
              className={cs(remainingDays < 5 ? styles.alert : styles.default)}
              data-cy="days-in-trial-count"
            >
              <span>{remainingDays} days left</span>
            </div>
          )}
        </div>
        <div className={styles.progressWrapper}>
          <ProgressBar
            className={styles.progressBar}
            now={completedSteps}
            min={0}
            max={totalSteps}
          />
          <span data-cy="trial-task-progress">
            {completedSteps}/{totalSteps} tasks complete
          </span>
        </div>
      </div>
    </div>
  )
}

export default OnboardingProgressBar
