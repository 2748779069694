import {
  CustomIcon,
  HorizontalList,
  RunStats,
  StatusIcon,
  Tag,
  Tooltip,
} from '@frontend/design-system'
import { RunsListItemFragment } from '@frontend/graphql-fixtures/src/generated/graphqlMockOperationTypes.gen'
import { Link } from '@reach/router'
import cs from 'clsx'
import moment from 'moment'
import React, { Fragment, FunctionComponent, forwardRef, useRef } from 'react'
import { IntelligenceScoresPill } from '~/common/intelligence/intelligence-scores-pill/IntelligenceScoresPill'
import { RunsListQuery } from '~/graphql-codegen-operations.gen'
import { readableRunStatusEnum, runStatusEnumToStatus } from '~/lib/utils-ts'
import { CommitAuthor } from '~/run/commit/CommitAuthor'
import { CommitBranch } from '~/run/commit/CommitBranch'
import { RunDuration } from '~/run/RunDuration'
import { SpecCi } from '~/specs/stats/SpecCi'
import useResizeObserver from 'use-resize-observer'

type RunData =
  | RunsListItemFragment
  | RunsListQuery['project']['runs']['nodes'][number]

interface RunsListItemProps {
  isPageHeader?: boolean
  run: RunData
  projectId: string
}

interface SlatTitleProps {
  isPageHeader: boolean
  run: RunData & {
    commit: {
      branchUrl?: string | null
    }
    status
    tags
  }
  slatAttributesHeight?: number
  slatAttributesWidth?: number
}
interface SlatDetailsProps {
  run: RunData & {
    commit: {
      branchUrl?: string | null
    }
    ci: {
      url?: string | null
    }
  }
  basePath: string
  isPageHeader: boolean
  ref?: React.Ref<HTMLDivElement | null>
}

export const Created = ({ startTime }) => (
  <div data-cy="run-list-item--creation-time">
    <CustomIcon name="time" variant="small" style={{ marginTop: -3 }} />
    Ran{' '}
    {moment(startTime).fromNow() === '1 secs ago'
      ? '1 sec ago'
      : moment(startTime).fromNow()}{' '}
  </div>
)

const BuildNumber = ({ buildNumber }) => (
  <span className="flex items-center">
    <CustomIcon name="number" variant="small" />
    {buildNumber}
  </span>
)

export const SlatTitle: FunctionComponent<SlatTitleProps> = ({
  isPageHeader,
  run,
  slatAttributesHeight,
  slatAttributesWidth,
}) => {
  return (
    <>
      <div className="run-list-item--status">
        <Tooltip
          placement="top"
          overlay={<span>{readableRunStatusEnum(run.status)}</span>}
        >
          <StatusIcon runStatusEnum={run.status} isCircle={isPageHeader} />
        </Tooltip>
      </div>

      <div
        className="run-list-item--title"
        style={{
          paddingBottom: slatAttributesHeight || 0,
          maxWidth: slatAttributesWidth,
        }}
      >
        <div className="run-list-item--title-container">
          <span data-cy="commit-info" data-pendo="commit-info">
            {run.commit.summary || 'No commit message available'}
          </span>
        </div>
        <div className="run-list-item--tag-container">
          {run.tags.map((tag) => (
            <Tag
              key={tag.id}
              className="run-list-item--tag"
              backgroundColor={tag.color}
            >
              {tag.name}
            </Tag>
          ))}
        </div>
      </div>
    </>
  )
}

const SlatDetails: FunctionComponent<SlatDetailsProps> = forwardRef(
  (props, ref) => {
    const { isPageHeader, run, basePath } = props

    const Wrapper = isPageHeader
      ? Fragment
      : ({ children }) => (
          <div className="run-list-item--details">{children}</div>
        )

    return (
      <Wrapper>
        <div className="run-list-item--attributes" ref={ref}>
          <HorizontalList margin={8}>
            <CommitAuthor
              authorName={run.commit.authorName || 'Not available'}
              authorAvatar={run.commit.authorAvatar}
              authorEmail={run.commit.authorEmail}
              shouldDisplayTooltip={isPageHeader}
            />
            <Tooltip
              placement="top"
              overlay={
                <span data-cy="run-list-item--full-date">
                  {moment(run.startTime).format('MMM D, YYYY hh:mm A')}
                </span>
              }
            >
              <span>
                <Created startTime={run.startTime} />
              </span>
            </Tooltip>
            <RunDuration run={run} showScheduledToCompleteDuration={false} />
            <CommitBranch
              branchName={run.commit.branch || 'Unknown branch'}
              projectId={run.project.id}
            />
            <SpecCi
              provider={run.ci.provider}
              ciUrl={run.ci.url}
              ciBuildNumberFormatted={run.ci.ciBuildNumberFormatted}
            />
            <BuildNumber buildNumber={run.buildNumber} />
          </HorizontalList>
        </div>
        <div className="run-list-item--results flex gap-3">
          {run.status !== 'RUNNING' && (
            <>
              {!isPageHeader &&
                ('uiCoverageResults' in run ||
                  'accessibilityResults' in run) && (
                  <IntelligenceScoresPill basePath={basePath} run={run} />
                )}
              <RunStats
                projectId={run.project.id}
                buildNumber={run.buildNumber}
                expanded
                passed={run.totalPassed}
                failed={run.totalFailed}
                pending={run.totalPending}
                skipped={run.totalSkipped}
                flaky={run.totalFlakyTests}
              />
            </>
          )}
        </div>
      </Wrapper>
    )
  }
)

export const RunsListItem: FunctionComponent<RunsListItemProps> = ({
  isPageHeader = false,
  run,
  projectId,
}) => {
  const basePath = `/projects/${projectId}/runs/${run.buildNumber}`

  const slatAttributesRef = useRef<HTMLDivElement>(null)
  const { height, width } = useResizeObserver({
    ref: slatAttributesRef,
  })

  if (!isPageHeader) {
    return (
      <li
        className="list-unstyled run-list-item-container"
        data-cy="runs-list-item"
        data-pendo="runs-list-item"
      >
        <Link
          className={`run-list-item ${runStatusEnumToStatus(run.status)}`}
          to={`${basePath}/overview`}
        >
          <SlatTitle
            isPageHeader={false}
            slatAttributesHeight={height}
            slatAttributesWidth={width}
            run={run}
          />
        </Link>
        <SlatDetails
          ref={slatAttributesRef}
          basePath={basePath}
          isPageHeader={false}
          run={run}
        />
      </li>
    )
  }

  return (
    <div
      className={cs(
        'run-list-item',
        runStatusEnumToStatus(run.status),
        'with-circle-icons'
      )}
    >
      <SlatTitle isPageHeader run={run} />
      <SlatDetails isPageHeader basePath={basePath} run={run} />
    </div>
  )
}
