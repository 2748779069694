// If the icon you're looking for already exists in the design system, import it from there! See https://design.cypress.io/Icons.html
// If it doesn't add it here. Once intelligence designs are stable, we'll move these into the Cypress design system

import React from 'react'
import { AxeImpactValue } from '~/accessibility/utils'
import { getProperNoun } from './formatting'
import { Discovery_Accessibility_RuleStatusType } from '@packages/types/src/allNexusTypes.gen'

export const IconTechnologyDocument = () => (
  <svg
    fill="none"
    height="41"
    viewBox="0 0 40 41"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1 35.5v-30c0-2.20914 1.79086-4 4-4h30c2.2091 0 4 1.79086 4 4v30c0 2.2091-1.7909 4-4 4h-30c-2.20914 0-4-1.7909-4-4z"
      fill="#f3f4fa"
    />
    <path
      d="m8 20.5h17m-17-6.8571h24m-24 13.7142h21.6667m5.3333-25.8571h-30c-2.20914 0-4 1.79086-4 4v30c0 2.2091 1.79086 4 4 4h30c2.2091 0 4-1.7909 4-4v-30c0-2.20914-1.7909-4-4-4z"
      stroke="#9095AD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export const IconUntested = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.999268C2.34315 0.999268 1 2.34241 1 3.99927V11.9993C1 13.6561 2.34315 14.9993 4 14.9993H5C5.55228 14.9993 6 14.5516 6 13.9993C6 13.447 5.55228 12.9993 5 12.9993H4C3.44772 12.9993 3 12.5516 3 11.9993V3.99927C3 3.44698 3.44772 2.99927 4 2.99927H12C12.5523 2.99927 13 3.44698 13 3.99927V4.99927C13 5.55155 13.4477 5.99927 14 5.99927C14.5523 5.99927 15 5.55155 15 4.99927V3.99927C15 2.34241 13.6569 0.999268 12 0.999268H4ZM9.70711 8.29216C9.31658 7.90164 8.68342 7.90164 8.29289 8.29216C7.90237 8.68268 7.90237 9.31585 8.29289 9.70637L10.0858 11.4993L8.29289 13.2922C7.90237 13.6827 7.90237 14.3159 8.29289 14.7064C8.68342 15.0969 9.31658 15.0969 9.70711 14.7064L11.5 12.9135L13.2929 14.7064C13.6834 15.0969 14.3166 15.0969 14.7071 14.7064C15.0976 14.3159 15.0976 13.6827 14.7071 13.2922L12.9142 11.4993L14.7071 9.70637C15.0976 9.31585 15.0976 8.68268 14.7071 8.29216C14.3166 7.90164 13.6834 7.90164 13.2929 8.29216L11.5 10.0851L9.70711 8.29216Z"
      fill="currentColor"
    />
  </svg>
)

export const IconTested = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ paddingTop: '1px', paddingLeft: '1px' }}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 -0.000732422C1.34315 -0.000732422 0 1.34241 0 2.99927V10.9993C0 12.6561 1.34315 13.9993 3 13.9993H4C4.55228 13.9993 5 13.5516 5 12.9993C5 12.447 4.55228 11.9993 4 11.9993H3C2.44772 11.9993 2 11.5516 2 10.9993V2.99927C2 2.44698 2.44772 1.99927 3 1.99927H11C11.5523 1.99927 12 2.44698 12 2.99927V3.99927C12 4.55155 12.4477 4.99927 13 4.99927C13.5523 4.99927 14 4.55155 14 3.99927V2.99927C14 1.34241 12.6569 -0.000732422 11 -0.000732422H3ZM13.7809 8.62396C14.1259 8.1927 14.056 7.56341 13.6247 7.2184C13.1934 6.87339 12.5641 6.94331 12.2191 7.37457L8.91708 11.5021L7.70711 10.2922C7.31658 9.90164 6.68342 9.90164 6.29289 10.2922C5.90237 10.6827 5.90237 11.3159 6.29289 11.7064L8.29289 13.7064C8.49403 13.9075 8.77129 14.0135 9.0553 13.9977C9.33931 13.982 9.60318 13.8461 9.78087 13.624L13.7809 8.62396Z"
      fill="currentColor"
    />
  </svg>
)

export const IconInteractivity = ({
  className,
  ...props
}: {
  className?: string
}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <g>
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.49945C7 0.947166 6.55228 0.499451 6 0.499451C5.44772 0.499451 5 0.947166 5 1.49945V2.24945C5 2.80174 5.44772 3.24945 6 3.24945C6.55228 3.24945 7 2.80174 7 2.24945V1.49945ZM9.92678 3.48689L10.4571 2.95656C10.8476 2.56603 10.8476 1.93287 10.4571 1.54234C10.0666 1.15182 9.43342 1.15182 9.04289 1.54234L8.51256 2.07267C8.12204 2.4632 8.12204 3.09636 8.51256 3.48689C8.90309 3.87741 9.53625 3.87741 9.92678 3.48689ZM3.70711 8.29234C4.09763 8.68287 4.09763 9.31603 3.70711 9.70656L3.17678 10.2369C2.78625 10.6274 2.15309 10.6274 1.76256 10.2369C1.37204 9.84636 1.37204 9.2132 1.76256 8.82267L2.29289 8.29234C2.68342 7.90182 3.31658 7.90182 3.70711 8.29234ZM2.95711 1.54234C2.56658 1.15182 1.93342 1.15182 1.54289 1.54234C1.15237 1.93287 1.15237 2.56603 1.54289 2.95656L2.07322 3.48689C2.46375 3.87741 3.09691 3.87741 3.48744 3.48689C3.87796 3.09636 3.87796 2.4632 3.48744 2.07267L2.95711 1.54234ZM0.5 5.99945C0.5 5.44717 0.947715 4.99945 1.5 4.99945H2.25C2.80228 4.99945 3.25 5.44717 3.25 5.99945C3.25 6.55174 2.80228 6.99945 2.25 6.99945H1.5C0.947715 6.99945 0.5 6.55174 0.5 5.99945ZM4.79417 4.55829L11.4284 5.88513C11.6151 5.92248 11.649 6.17497 11.4786 6.26013L9.24819 7.37536C9.12609 7.43641 9.09968 7.59913 9.19621 7.69566L11.1086 9.60803C11.1867 9.68613 11.1867 9.81277 11.1086 9.89087L9.89142 11.108C9.81332 11.1861 9.68668 11.1861 9.60858 11.108L7.69621 9.19566C7.59968 9.09913 7.43695 9.12554 7.37591 9.24764L6.26068 11.4781C6.17552 11.6484 5.92303 11.6146 5.88568 11.4279L4.55884 4.79362C4.53085 4.65368 4.65423 4.5303 4.79417 4.55829Z"
        fill="#747994"
      />
    </g>
  </svg>
)

export const AccessibilityIcon = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 11.9993C23 18.0744 18.0751 22.9993 12 22.9993C5.92487 22.9993 1 18.0744 1 11.9993C1 5.92414 5.92487 0.999268 12 0.999268C18.0751 0.999268 23 5.92414 23 11.9993Z"
      fill="#F3F4FA"
    />
    <path
      d="M12 10.9993L16 9.99927M12 10.9993L8 9.99927M12 10.9993V13.9993M12 13.9993L9 17.9993M12 13.9993L15 17.9993M23 11.9993C23 18.0744 18.0751 22.9993 12 22.9993C5.92487 22.9993 1 18.0744 1 11.9993C1 5.92414 5.92487 0.999268 12 0.999268C18.0751 0.999268 23 5.92414 23 11.9993ZM12 7.99927V7.99927C12.5523 7.99927 13 7.55155 13 6.99927V6.99927C13 6.44698 12.5523 5.99927 12 5.99927V5.99927C11.4477 5.99927 11 6.44698 11 6.99927V6.99927C11 7.55155 11.4477 7.99927 12 7.99927Z"
      stroke="#747994"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const AccessibilityIconWithGreenDot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <rect
      x="2"
      y="2.5"
      width="20"
      height="20"
      rx="10"
      fill="#F3F4FA"
      stroke="#9095AD"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.5C14 8.60457 13.1046 9.5 12 9.5C10.8954 9.5 10 8.60457 10 7.5C10 6.39543 10.8954 5.5 12 5.5C13.1046 5.5 14 6.39543 14 7.5ZM8.24277 9.52985C7.70698 9.3959 7.16404 9.72166 7.03009 10.2575C6.89615 10.7933 7.22191 11.3362 7.7577 11.4701L10.6215 12.1861C10.8441 12.2417 11.0002 12.4417 11.0002 12.6712V13.5C11.0002 13.9327 10.8599 14.3538 10.6002 14.7L8.20024 17.9C7.86887 18.3418 7.95841 18.9686 8.40024 19.3C8.84206 19.6314 9.46887 19.5418 9.80024 19.1L11.8402 16.38C11.9202 16.2733 12.0802 16.2733 12.1602 16.38L14.2002 19.1C14.5316 19.5418 15.1584 19.6314 15.6002 19.3C16.0421 18.9686 16.1316 18.3418 15.8002 17.9L13.4002 14.7C13.1406 14.3538 13.0002 13.9327 13.0002 13.5V12.6712C13.0002 12.4417 13.1564 12.2417 13.379 12.1861L16.2428 11.4701C16.7786 11.3362 17.1043 10.7933 16.9704 10.2575C16.8364 9.72166 16.2935 9.3959 15.7577 9.52985L12.4853 10.3479C12.1668 10.4276 11.8336 10.4276 11.5152 10.3479L8.24277 9.52985Z"
      fill="#9095AD"
    />
    <path
      d="M20 17.001C21.933 17.001 23.5 18.568 23.5 20.501C23.5 22.434 21.933 24.001 20 24.001C18.067 24.001 16.5 22.434 16.5 20.501C16.5 18.568 18.067 17.001 20 17.001Z"
      fill="#00814D"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
)

export const IconTechnologyMagnifyingGlassNoResults = (props: {
  className?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={props.className}
  >
    <path
      d="M18 10.9995C18 13.0706 17.1605 14.9456 15.8033 16.3028C14.4461 17.66 12.5711 18.4995 10.5 18.4995C6.35786 18.4995 3 15.1416 3 10.9995C3 6.85738 6.35786 3.49951 10.5 3.49951C14.6421 3.49951 18 6.85738 18 10.9995Z"
      fill="#F3F4FA"
    />
    <path
      d="M15.8033 16.3028C17.1605 14.9456 18 13.0706 18 10.9995C18 6.85738 14.6421 3.49951 10.5 3.49951C6.35786 3.49951 3 6.85738 3 10.9995C3 15.1416 6.35786 18.4995 10.5 18.4995C12.5711 18.4995 14.4461 17.66 15.8033 16.3028ZM15.8033 16.3028L21 21.4995"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 13L10.5 11M12.5 9L10.5 11M10.5 11L8.5 9M10.5 11L12.5 13"
      stroke="#E45770"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconOneHundredPercentAccessibility = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="4.99927"
      width="38"
      height="38"
      rx="19"
      fill="#F3F4FA"
      stroke="#9095AD"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 19C25.3807 19 26.5 17.8807 26.5 16.5C26.5 15.1193 25.3807 14 24 14C22.6193 14 21.5 15.1193 21.5 16.5C21.5 17.8807 22.6193 19 24 19ZM17.2425 19.0299C16.7067 18.8959 16.1638 19.2217 16.0298 19.7575C15.8959 20.2933 16.2217 20.8362 16.7575 20.9701L23 22.5308V26.6667L17.95 33.4C17.6186 33.8418 17.7082 34.4686 18.15 34.8C18.5918 35.1314 19.2186 35.0418 19.55 34.6L24 28.6667L28.45 34.6C28.7814 35.0418 29.4082 35.1314 29.85 34.8C30.2918 34.4686 30.3814 33.8418 30.05 33.4L25 26.6667V22.5308L31.2425 20.9701C31.7783 20.8362 32.1041 20.2933 31.9701 19.7575C31.8362 19.2217 31.2933 18.8959 30.7575 19.0299L24 20.7192L17.2425 19.0299Z"
      fill="#9095AD"
    />
    <path
      d="M44 38.001C44 34.6873 41.3137 32.001 38 32.001C34.6863 32.001 32 34.6873 32 38.001C32 41.3147 34.6863 44.001 38 44.001C41.3137 44.001 44 41.3147 44 38.001Z"
      fill="#69D3A7"
    />
    <path
      d="M36.7071 37.7939C36.3166 37.4033 35.6834 37.4033 35.2929 37.7939C34.9024 38.1844 34.9024 38.8176 35.2929 39.2081L36.7071 37.7939ZM37.5 40.001L36.7929 40.7081C37.0007 40.9159 37.2895 41.0218 37.5825 40.9976C37.8754 40.9733 38.1429 40.8214 38.3137 40.5822L37.5 40.001ZM40.8137 37.0822C41.1347 36.6328 41.0307 36.0083 40.5812 35.6872C40.1318 35.3662 39.5073 35.4703 39.1863 35.9197L40.8137 37.0822ZM45 38.001C45 34.135 41.866 31.001 38 31.001V33.001C40.7614 33.001 43 35.2396 43 38.001H45ZM38 31.001C34.134 31.001 31 34.135 31 38.001H33C33 35.2396 35.2386 33.001 38 33.001V31.001ZM31 38.001C31 41.867 34.134 45.001 38 45.001V43.001C35.2386 43.001 33 40.7624 33 38.001H31ZM38 45.001C41.866 45.001 45 41.867 45 38.001H43C43 40.7624 40.7614 43.001 38 43.001V45.001ZM35.2929 39.2081L36.7929 40.7081L38.2071 39.2939L36.7071 37.7939L35.2929 39.2081ZM38.3137 40.5822L40.8137 37.0822L39.1863 35.9197L36.6863 39.4197L38.3137 40.5822Z"
      fill="#00814D"
    />
  </svg>
)

export const IconObjectAccessibilityOutline = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
      fill="#F3F4FA"
    />
    <path
      d="M8 8L11 7M8 8L5 7M8 8V10M8 10L6 12M8 10L10 12M7.5 4.5V4.5C7.5 4.77614 7.72386 5 8 5V5V5C8.27614 5 8.5 4.77614 8.5 4.5V4.5M7.5 4.5V4.5C7.5 4.22386 7.72386 4 8 4V4V4C8.27614 4 8.5 4.22386 8.5 4.5V4.5M7.5 4.5H8.5M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const A11yMiniIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{getProperNoun('accessibility')}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00003 3.99927C6.82845 3.99927 7.50003 3.32769 7.50003 2.49927C7.50003 1.67084 6.82845 0.999268 6.00003 0.999268C5.1716 0.999268 4.50003 1.67084 4.50003 2.49927C4.50003 3.32769 5.1716 3.99927 6.00003 3.99927ZM3.31625 4.05058C2.79231 3.87594 2.22599 4.1591 2.05134 4.68304C1.8767 5.20698 2.15986 5.7733 2.6838 5.94795L5.00003 6.72003V7.58505L3.29292 9.29216C2.9024 9.68268 2.9024 10.3159 3.29292 10.7064C3.68344 11.0969 4.31661 11.0969 4.70713 10.7064L6.00003 9.41348L7.29292 10.7064C7.68344 11.0969 8.31661 11.0969 8.70713 10.7064C9.09766 10.3159 9.09766 9.68268 8.70713 9.29216L7.00003 7.58505V6.72003L9.31626 5.94795C9.8402 5.7733 10.1234 5.20698 9.94871 4.68304C9.77406 4.1591 9.20774 3.87594 8.6838 4.05058L6.00003 4.94517L3.31625 4.05058Z"
      fill="#747994"
    />
  </svg>
)

export const UiCoverageMiniIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{getProperNoun('ui-coverage')}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0.5C6.55228 0.5 7 0.947715 7 1.5V2.25C7 2.80228 6.55228 3.25 6 3.25C5.44772 3.25 5 2.80228 5 2.25V1.5C5 0.947715 5.44772 0.5 6 0.5ZM10.4571 2.95711L9.92678 3.48744C9.53625 3.87796 8.90309 3.87796 8.51256 3.48744C8.12204 3.09691 8.12204 2.46375 8.51256 2.07322L9.04289 1.54289C9.43342 1.15237 10.0666 1.15237 10.4571 1.54289C10.8476 1.93342 10.8476 2.56658 10.4571 2.95711ZM3.70711 9.70711C4.09763 9.31658 4.09763 8.68342 3.70711 8.29289C3.31658 7.90237 2.68342 7.90237 2.29289 8.29289L1.76256 8.82322C1.37204 9.21375 1.37204 9.84691 1.76256 10.2374C2.15309 10.628 2.78625 10.628 3.17678 10.2374L3.70711 9.70711ZM1.54289 1.54289C1.93342 1.15237 2.56658 1.15237 2.95711 1.54289L3.48744 2.07322C3.87796 2.46375 3.87796 3.09691 3.48744 3.48744C3.09691 3.87796 2.46375 3.87796 2.07322 3.48744L1.54289 2.95711C1.15237 2.56658 1.15237 1.93342 1.54289 1.54289ZM1.5 5C0.947715 5 0.5 5.44772 0.5 6C0.5 6.55228 0.947715 7 1.5 7H2.25C2.80228 7 3.25 6.55228 3.25 6C3.25 5.44772 2.80228 5 2.25 5H1.5Z"
      fill="#747994"
    />
    <path
      d="M11.4284 5.88568L4.79417 4.55883C4.65423 4.53085 4.53085 4.65423 4.55883 4.79417L5.88568 11.4284C5.92303 11.6151 6.17552 11.649 6.26068 11.4786L7.3759 9.24819C7.43695 9.12609 7.59968 9.09968 7.69621 9.19621L9.60858 11.1086C9.68668 11.1867 9.81332 11.1867 9.89142 11.1086L11.1086 9.89142C11.1867 9.81332 11.1867 9.68668 11.1086 9.60858L9.19621 7.69621C9.09968 7.59968 9.12609 7.43695 9.24819 7.37591L11.4786 6.26068C11.649 6.17552 11.6151 5.92303 11.4284 5.88568Z"
      fill="#747994"
    />
  </svg>
)

export const LockedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V9C13 7.89543 12.1046 7 11 7H5C3.89543 7 3 7.89543 3 9V12Z"
      fill="#E1E3ED"
    />
    <path
      d="M5 7V7C3.89543 7 3 7.89544 3 9V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V9C13 7.89543 12.1046 7 11 7V7M5 7V5C5 3.34315 6.34315 2 8 2V2C9.65685 2 11 3.34315 11 5V7M5 7H11M8 10V11"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LockedMiniIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9C2 10.1046 2.89543 11 4 11H8C9.10457 11 10 10.1046 10 9V7C10 5.89543 9.10457 5 8 5H4C2.89543 5 2 5.89543 2 7V9Z"
      fill="#E1E3ED"
    />
    <path
      d="M4 5V5C2.89543 5 2 5.89543 2 7V9C2 10.1046 2.89543 11 4 11H8C9.10457 11 10 10.1046 10 9V7C10 5.89543 9.10457 5 8 5V5M4 5V3C4 1.89543 4.89543 1 6 1V1C7.10457 1 8 1.89543 8 3V5M4 5H8M6 8V8.01"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconActionFilter = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H11M2.5 4H9.5M4 7H8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const InteractivityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
  >
    <rect
      x="5"
      y="5.49927"
      width="38"
      height="38"
      rx="4"
      fill="#F3F4FA"
      stroke="#9095AD"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12.5C23 11.9477 22.5523 11.5 22 11.5C21.4477 11.5 21 11.9477 21 12.5V16.5C21 17.0523 21.4477 17.5 22 17.5C22.5523 17.5 23 17.0523 23 16.5V12.5ZM30.7071 16.2071C31.0976 15.8166 31.0976 15.1834 30.7071 14.7929C30.3166 14.4024 29.6834 14.4024 29.2929 14.7929L26.4645 17.6213C26.0739 18.0118 26.0739 18.645 26.4645 19.0355C26.855 19.4261 27.4882 19.4261 27.8787 19.0355L30.7071 16.2071ZM17.7071 27.7929C18.0976 28.1834 18.0976 28.8166 17.7071 29.2071L14.8787 32.0355C14.4882 32.4261 13.855 32.4261 13.4645 32.0355C13.0739 31.645 13.0739 31.0118 13.4645 30.6213L16.2929 27.7929C16.6834 27.4024 17.3166 27.4024 17.7071 27.7929ZM14.7071 14.7929C14.3166 14.4024 13.6834 14.4024 13.2929 14.7929C12.9024 15.1834 12.9024 15.8166 13.2929 16.2071L16.1213 19.0355C16.5118 19.4261 17.145 19.4261 17.5355 19.0355C17.9261 18.645 17.9261 18.0118 17.5355 17.6213L14.7071 14.7929ZM11 23.5C11 22.9477 11.4477 22.5 12 22.5H16C16.5523 22.5 17 22.9477 17 23.5C17 24.0523 16.5523 24.5 16 24.5H12C11.4477 24.5 11 24.0523 11 23.5ZM21.7354 22.6471L35.571 25.4142C36.0379 25.5076 36.1224 26.1388 35.6966 26.3517L31.2205 28.5898C30.9152 28.7424 30.8492 29.1492 31.0905 29.3905L35.0464 33.3464C35.2417 33.5417 35.2417 33.8583 35.0464 34.0536L32.5536 36.5464C32.3583 36.7417 32.0417 36.7417 31.8464 36.5464L27.8905 32.5905C27.6492 32.3492 27.2424 32.4152 27.0898 32.7205L24.8517 37.1966C24.6388 37.6224 24.0076 37.5379 23.9142 37.071L21.1471 23.2354C21.0771 22.8856 21.3856 22.5771 21.7354 22.6471Z"
      fill="#9095AD"
    />
  </svg>
)

export const IconDisabledAccessibility = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="4.99927"
      width="38"
      height="38"
      rx="19"
      fill="#F3F4FA"
      stroke="#747994"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 19C25.3807 19 26.5 17.8807 26.5 16.5C26.5 15.1193 25.3807 14 24 14C22.6193 14 21.5 15.1193 21.5 16.5C21.5 17.8807 22.6193 19 24 19ZM17.2425 19.0299C16.7067 18.8959 16.1638 19.2217 16.0298 19.7575C15.8959 20.2933 16.2217 20.8362 16.7575 20.9701L23 22.5308V26.6667L17.95 33.4C17.6186 33.8418 17.7082 34.4686 18.15 34.8C18.5918 35.1314 19.2186 35.0418 19.55 34.6L24 28.6667L28.45 34.6C28.7814 35.0418 29.4082 35.1314 29.85 34.8C30.2918 34.4686 30.3814 33.8418 30.05 33.4L25 26.6667V22.5308L31.2425 20.9701C31.7783 20.8362 32.1041 20.2933 31.9701 19.7575C31.8362 19.2217 31.2933 18.8959 30.7575 19.0299L24 20.7192L17.2425 19.0299Z"
      fill="#747994"
    />
    <circle cx="37" cy="37" r="8" fill="white" />
    <path
      d="M43 37.001C43 33.6873 40.3137 31.001 37 31.001C33.6863 31.001 31 33.6873 31 37.001C31 40.3147 33.6863 43.001 37 43.001C40.3137 43.001 43 40.3147 43 37.001Z"
      fill="#E1E3ED"
    />
    <path
      d="M32.7412 41.2274L41.2656 32.7814M43 37.001C43 33.6873 40.3137 31.001 37 31.001C33.6863 31.001 31 33.6873 31 37.001C31 40.3147 33.6863 43.001 37 43.001C40.3137 43.001 43 40.3147 43 37.001Z"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const IconDisabledUICoverage = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="4.99927"
      width="38"
      height="38"
      rx="4"
      fill="#F3F4FA"
      stroke="#9095AD"
      strokeWidth="2"
    />
    <path
      d="M33.4284 21.8853L19.2942 19.0585C19.1542 19.0305 19.0308 19.1539 19.0588 19.2938L21.8857 33.428C21.923 33.6148 22.1755 33.6486 22.2607 33.4783L24.8759 28.2478C24.937 28.1257 25.0997 28.0993 25.1962 28.1958L29.3586 32.3582C29.4367 32.4363 29.5633 32.4363 29.6414 32.3582L32.3586 29.6411C32.4367 29.563 32.4367 29.4363 32.3586 29.3582L28.1962 25.1958C28.0997 25.0993 28.1261 24.9366 28.2482 24.8755L33.4786 22.2603C33.649 22.1752 33.6151 21.9227 33.4284 21.8853Z"
      fill="#E1E3ED"
      stroke="#747994"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11.9996C22.5523 11.9996 23 12.4473 23 12.9996V14.4996C23 15.0519 22.5523 15.4996 22 15.4996C21.4477 15.4996 21 15.0519 21 14.4996V12.9996C21 12.4473 21.4477 11.9996 22 11.9996ZM30.2071 15.2067L29.1464 16.2674C28.7559 16.6579 28.1228 16.6579 27.7322 16.2674C27.3417 15.8769 27.3417 15.2437 27.7322 14.8532L28.7929 13.7925C29.1834 13.402 29.8166 13.402 30.2071 13.7925C30.5976 14.1831 30.5976 14.8162 30.2071 15.2067ZM16.7071 28.7067C17.0976 28.3162 17.0976 27.6831 16.7071 27.2925C16.3166 26.902 15.6834 26.902 15.2929 27.2925L14.2322 28.3532C13.8417 28.7437 13.8417 29.3769 14.2322 29.7674C14.6228 30.1579 15.2559 30.1579 15.6464 29.7674L16.7071 28.7067ZM13.7929 13.7925C14.1834 13.402 14.8166 13.402 15.2071 13.7925L16.2678 14.8532C16.6583 15.2437 16.6583 15.8769 16.2678 16.2674C15.8772 16.6579 15.2441 16.6579 14.8536 16.2674L13.7929 15.2067C13.4024 14.8162 13.4024 14.1831 13.7929 13.7925ZM13 20.9996C12.4477 20.9996 12 21.4473 12 21.9996C12 22.5519 12.4477 22.9996 13 22.9996H14.5C15.0523 22.9996 15.5 22.5519 15.5 21.9996C15.5 21.4473 15.0523 20.9996 14.5 20.9996H13Z"
      fill="#747994"
    />
    <circle cx="40" cy="40" r="8" fill="white" />
    <path
      d="M46 40.001C46 36.6873 43.3137 34.001 40 34.001C36.6863 34.001 34 36.6873 34 40.001C34 43.3147 36.6863 46.001 40 46.001C43.3137 46.001 46 43.3147 46 40.001Z"
      fill="#E1E3ED"
    />
    <path
      d="M35.7412 44.2274L44.2656 35.7814M46 40.001C46 36.6873 43.3137 34.001 40 34.001C36.6863 34.001 34 36.6873 34 40.001C34 43.3147 36.6863 46.001 40 46.001C43.3137 46.001 46 43.3147 46 40.001Z"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const IconAccessibilityWarning = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="4.99902"
      width="38"
      height="38"
      rx="19"
      fill="#F3F4FA"
      stroke="#747994"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0002 19C25.381 19 26.5002 17.8807 26.5002 16.5C26.5002 15.1193 25.381 14 24.0002 14C22.6195 14 21.5002 15.1193 21.5002 16.5C21.5002 17.8807 22.6195 19 24.0002 19ZM17.2428 19.0299C16.707 18.8959 16.164 19.2217 16.0301 19.7575C15.8961 20.2933 16.2219 20.8362 16.7577 20.9701L23.0002 22.5308V26.6667L17.9502 33.4C17.6189 33.8418 17.7084 34.4686 18.1502 34.8C18.5921 35.1314 19.2189 35.0418 19.5502 34.6L24.0002 28.6667L28.4502 34.6C28.7816 35.0418 29.4084 35.1314 29.8502 34.8C30.2921 34.4686 30.3816 33.8418 30.0502 33.4L25.0002 26.6667V22.5308L31.2428 20.9701C31.7786 20.8362 32.1043 20.2933 31.9704 19.7575C31.8364 19.2217 31.2935 18.8959 30.7577 19.0299L24.0002 20.7192L17.2428 19.0299Z"
      fill="#747994"
    />
    <path
      d="M44 38.001C44 34.6873 41.3137 32.001 38 32.001C34.6863 32.001 32 34.6873 32 38.001C32 41.3147 34.6863 44.001 38 44.001C41.3137 44.001 44 41.3147 44 38.001Z"
      fill="#F1E08F"
    />
    <path
      d="M38 35V38.5M38 41V41.001M44 38.001C44 34.6873 41.3137 32.001 38 32.001C34.6863 32.001 32 34.6873 32 38.001C32 41.3147 34.6863 44.001 38 44.001C41.3137 44.001 44 41.3147 44 38.001Z"
      stroke="#BD5800"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const IconInteractivityWarning = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="4.99902"
      width="38"
      height="38"
      rx="4"
      fill="#F3F4FA"
      stroke="#9095AD"
      strokeWidth="2"
    />
    <path
      d="M33.4284 21.8857L19.2942 19.0588C19.1542 19.0308 19.0308 19.1542 19.0588 19.2942L21.8857 33.4284C21.923 33.6151 22.1755 33.649 22.2607 33.4786L24.8759 28.2482C24.937 28.1261 25.0997 28.0997 25.1962 28.1962L29.3586 32.3586C29.4367 32.4367 29.5633 32.4367 29.6414 32.3586L32.3586 29.6414C32.4367 29.5633 32.4367 29.4367 32.3586 29.3586L28.1962 25.1962C28.0997 25.0997 28.1261 24.937 28.2482 24.8759L33.4786 22.2607C33.649 22.1755 33.6151 21.923 33.4284 21.8857Z"
      fill="#E1E3ED"
      stroke="#747994"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22.5523 12 23 12.4477 23 13V14.5C23 15.0523 22.5523 15.5 22 15.5C21.4477 15.5 21 15.0523 21 14.5V13C21 12.4477 21.4477 12 22 12ZM30.2071 15.2071L29.1464 16.2678C28.7559 16.6583 28.1228 16.6583 27.7322 16.2678C27.3417 15.8772 27.3417 15.2441 27.7322 14.8536L28.7929 13.7929C29.1834 13.4024 29.8166 13.4024 30.2071 13.7929C30.5976 14.1834 30.5976 14.8166 30.2071 15.2071ZM16.7071 28.7071C17.0976 28.3166 17.0976 27.6834 16.7071 27.2929C16.3166 26.9024 15.6834 26.9024 15.2929 27.2929L14.2322 28.3536C13.8417 28.7441 13.8417 29.3772 14.2322 29.7678C14.6228 30.1583 15.2559 30.1583 15.6464 29.7678L16.7071 28.7071ZM13.7929 13.7929C14.1834 13.4024 14.8166 13.4024 15.2071 13.7929L16.2678 14.8536C16.6583 15.2441 16.6583 15.8772 16.2678 16.2678C15.8772 16.6583 15.2441 16.6583 14.8536 16.2678L13.7929 15.2071C13.4024 14.8166 13.4024 14.1834 13.7929 13.7929ZM13 21C12.4477 21 12 21.4477 12 22C12 22.5523 12.4477 23 13 23H14.5C15.0523 23 15.5 22.5523 15.5 22C15.5 21.4477 15.0523 21 14.5 21H13Z"
      fill="#747994"
    />
    <path
      d="M46 40.001C46 36.6873 43.3137 34.001 40 34.001C36.6863 34.001 34 36.6873 34 40.001C34 43.3147 36.6863 46.001 40 46.001C43.3137 46.001 46 43.3147 46 40.001Z"
      fill="#F1E08F"
    />
    <path
      d="M40 37V40.5M40 43V43.001M46 40.001C46 36.6873 43.3137 34.001 40 34.001C36.6863 34.001 34 36.6873 34 40.001C34 43.3147 36.6863 46.001 40 46.001C43.3137 46.001 46 43.3147 46 40.001Z"
      stroke="#BD5800"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export const StaticLoadingIcon = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2.5" y="2" width="52" height="52" rx="26" fill="white" />
    <rect
      x="2.5"
      y="2"
      width="52"
      height="52"
      rx="26"
      stroke="white"
      strokeWidth="4"
    />
    <path
      d="M21.377 23.0494C23.319 23.0494 24.901 24.085 25.7139 25.8915L25.7782 26.0329L29.0398 24.9253L28.9704 24.7583C27.7074 21.6823 24.7981 19.7704 21.377 19.7704C18.9719 19.7704 17.0169 20.5414 15.4015 22.1243C13.7964 23.697 12.9835 25.6757 12.9835 28.0064C12.9835 30.3166 13.7964 32.285 15.4015 33.8577C17.0169 35.4407 18.9719 36.2116 21.377 36.2116C24.7981 36.2116 27.7074 34.2997 28.9704 31.2263L29.0398 31.0593L25.773 29.9491L25.7113 30.0956C24.9833 31.8713 23.3628 32.9326 21.377 32.9326C20.0239 32.9326 18.8818 32.4598 17.9763 31.5295C17.0606 30.5864 16.5976 29.4018 16.5976 28.009C16.5976 26.6059 17.0503 25.4444 17.9763 24.4576C18.8792 23.5222 20.0239 23.0494 21.377 23.0494Z"
      fill="#1B1E2E"
    />
    <path
      d="M41.8061 20.0737L37.158 31.8353L32.479 20.0737H28.6514L35.2133 36.1268L30.5446 47.449L30.328 47.963C30.1788 48.3224 29.8533 48.5752 29.4776 48.6273C29.1537 48.6423 28.8277 48.65 28.5 48.65C28.4323 48.65 28.3647 48.6497 28.2972 48.649C16.9859 48.5402 7.85 39.337 7.85 28C7.85 16.5953 17.0953 7.35 28.5 7.35C39.9047 7.35 49.15 16.5953 49.15 28C49.15 36.5719 43.9271 43.924 36.49 47.0474L35.2772 49.9958C35.0987 50.43 34.8755 50.8377 34.6141 51.2142C44.9075 48.5105 52.5 39.1419 52.5 28C52.5 14.7452 41.7548 4 28.5 4C15.2452 4 4.5 14.7452 4.5 28C4.5 41.1872 15.1357 51.8902 28.2972 51.9992C28.3247 51.9994 29.5196 51.9794 29.5177 51.9794C31.236 51.9075 32.7717 50.8308 33.4276 49.235L33.9694 47.9179L45.4228 20.0737H41.8061Z"
      fill="#1B1E2E"
    />
    <g style={{ mixBlendMode: 'lighten' }}>
      <mask
        id="mask0_1385_30422"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-20"
        y="-8"
        width="49"
        height="72"
      >
        <rect
          width="48"
          height="72"
          rx="10"
          transform="matrix(-1 0 0 1 28.5 -8)"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1385_30422)">
        <circle
          cx="28.5"
          cy="28"
          r="22.325"
          transform="rotate(-180 28.5 28)"
          stroke="#69D3A7"
          strokeWidth="3.35"
        />
      </g>
    </g>
  </svg>
)

export const IconBeyondDataRetention = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5858 9.08579C18.2107 8.71071 17.702 8.5 17.1716 8.5H6C5.44772 8.5 5 8.94772 5 9.5V14.5H17.1716C17.702 14.5 18.2107 14.2893 18.5858 13.9142L21 11.5L18.5858 9.08579Z"
      fill="#E1E3ED"
    />
    <path
      d="M21 11.5L18.5858 9.08579C18.2107 8.71071 17.702 8.5 17.1716 8.5H6C5.44772 8.5 5 8.94772 5 9.5V14.5M21 11.5H41C42.1046 11.5 43 12.3954 43 13.5V38.5C43 39.6046 42.1046 40.5 41 40.5H7C5.89543 40.5 5 39.6046 5 38.5V14.5M21 11.5L18.5858 13.9142C18.2107 14.2893 17.702 14.5 17.1716 14.5H5"
      stroke="#9095AD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 41.5C25 42.6046 25.8954 43.5 27 43.5H35C36.1046 43.5 37 42.6046 37 41.5V36.5C37 35.3954 36.1046 34.5 35 34.5H34H28H27C25.8954 34.5 25 35.3954 25 36.5V41.5Z"
      fill="#F1E08F"
    />
    <path
      d="M28 34.5H27C25.8954 34.5 25 35.3954 25 36.5V41.5C25 42.6046 25.8954 43.5 27 43.5H35C36.1046 43.5 37 42.6046 37 41.5V36.5C37 35.3954 36.1046 34.5 35 34.5H34M28 34.5V32.5C28 30.8431 29.3431 29.5 31 29.5V29.5C32.6569 29.5 34 30.8431 34 32.5V34.5M28 34.5H34M31 38.5V39.5"
      stroke="#DB7903"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconElementStatusX16 = (
  status: Discovery_Accessibility_RuleStatusType,
  className?: string
) => {
  switch (status) {
    case 'incomplete':
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
          className={className || ''}
        >
          <path
            clipRule="evenodd"
            d="m1 2.5c0-.82843.67157-1.5 1.5-1.5h1.5c.55228 0 1 .44772 1 1s-.44772 1-1 1h-1v1c0 .55228-.44772 1-1 1s-1-.44772-1-1zm5-.5c0-.55228.44772-1 1-1h2c.55228 0 1 .44772 1 1s-.44772 1-1 1h-2c-.55228 0-1-.44772-1-1zm5-.00037c.0002-.55228.4481-.999832 1.0004-.99963l1.5001.00055c.8283.0003 1.4995.67179 1.4995 1.5v1.49945c0 .55228-.4477 1-1 1s-1-.44772-1-1v-.99963l-1.0004-.00037c-.5523-.0002-.9998-.44808-.9996-1.00037zm-9 4.0011c.55228 0 1 .44772 1 1v2c0 .55229-.44772.99997-1 .99997s-1-.44768-1-.99997v-2c0-.55228.44772-1 1-1zm0 4.99927c.55228 0 1 .4477 1 1v1h1c.55228 0 1 .4477 1 1s-.44772 1-1 1h-1.5c-.82843 0-1.5-.6716-1.5-1.5v-1.5c0-.5523.44772-1 1-1z"
            fill="#db7903"
            fillRule="evenodd"
          />
          <path d="m15 11.0007c0-2.20911-1.7909-3.99997-4-3.99997-2.20914 0-4 1.79086-4 3.99997 0 2.2092 1.79086 4 4 4 2.2091 0 4-1.7908 4-4z" />
          <path
            d="m11 9.80073v2.39997m4-1.2c0-2.20911-1.7909-3.99997-4-3.99997-2.20914 0-4 1.79086-4 3.99997 0 2.2092 1.79086 4 4 4 2.2091 0 4-1.7908 4-4z"
            stroke="#db7903"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </svg>
      )
    case 'fail':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#e45770"
          xmlns="http://www.w3.org/2000/svg"
          className={className || ''}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 1C1.67157 1 1 1.67157 1 2.5V4C1 4.55228 1.44772 5 2 5C2.55228 5 3 4.55228 3 4V3H4C4.55228 3 5 2.55228 5 2C5 1.44772 4.55228 1 4 1H2.5ZM7 1C6.44772 1 6 1.44772 6 2C6 2.55228 6.44772 3 7 3H9C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1H7ZM12.0004 1C11.4481 0.999798 11.0002 1.44735 11 1.99963C10.9998 2.55192 11.4473 2.9998 11.9996 3L13 3.00037V4C13 4.55228 13.4477 5 14 5C14.5523 5 15 4.55228 15 4V2.50055C15 1.67234 14.3288 1.00085 13.5005 1.00055L12.0004 1ZM3 7.00073C3 6.44845 2.55228 6.00073 2 6.00073C1.44772 6.00073 1 6.44845 1 7.00073V9.00073C1 9.55302 1.44772 10.0007 2 10.0007C2.55228 10.0007 3 9.55302 3 9.00073V7.00073ZM3 12C3 11.4477 2.55228 11 2 11C1.44772 11 1 11.4477 1 12V13.5C1 14.3284 1.67157 15 2.5 15H4C4.55228 15 5 14.5523 5 14C5 13.4477 4.55228 13 4 13H3V12ZM12.5 11.0858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L11.0858 12.5L9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L12.5 13.9142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.9142 12.5L15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L12.5 11.0858Z"
            className={className || ''}
          />
        </svg>
      )
    case 'inapplicable':
    case 'ignore':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 2.5C1 1.67157 1.67157 1 2.5 1H4C4.55228 1 5 1.44772 5 2C5 2.55228 4.55228 3 4 3H3V4C3 4.55228 2.55228 5 2 5C1.44772 5 1 4.55228 1 4V2.5ZM6 2C6 1.44772 6.44772 1 7 1H9C9.55228 1 10 1.44772 10 2C10 2.55228 9.55228 3 9 3H7C6.44772 3 6 2.55228 6 2ZM11 1.99963C11.0002 1.44735 11.4481 0.999798 12.0004 1L13.5005 1.00055C14.3288 1.00085 15 1.67234 15 2.50055V4C15 4.55228 14.5523 5 14 5C13.4477 5 13 4.55228 13 4V3.00037L11.9996 3C11.4473 2.9998 10.9998 2.55192 11 1.99963ZM2 6.00073C2.55228 6.00073 3 6.44845 3 7.00073V9.00073C3 9.55302 2.55228 10.0007 2 10.0007C1.44772 10.0007 1 9.55302 1 9.00073V7.00073C1 6.44845 1.44772 6.00073 2 6.00073ZM2 11C2.55228 11 3 11.4477 3 12V13H4C4.55228 13 5 13.4477 5 14C5 14.5523 4.55228 15 4 15H2.5C1.67157 15 1 14.3284 1 13.5V12C1 11.4477 1.44772 11 2 11Z"
            fill="#9095ad"
          />
          <path d="M15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z" />
          <path
            d="M8.5 13.5L13.5 8.5M15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z"
            stroke="#9095ad"
            strokeWidth="2"
          />
        </svg>
      )
    case 'pass':
    default:
      return null
  }
}

export const IconImpactsX16 = (impact: AxeImpactValue, darkMode?: boolean) => {
  const smallRectStroke = darkMode ? '#AFB3C7' : '#D0D2E0'
  switch (impact) {
    case 'critical':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke={smallRectStroke}>
            <rect height="1" rx=".5" width="2" x="5.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="9.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="13.5" y="7.5" />
          </g>
          <rect
            x="0.5"
            y="2.5"
            width="3"
            height="11"
            rx="1.5"
            fill="#C62B49"
            stroke={darkMode ? '#E45770' : '#9F1331'}
          />
        </svg>
      )
    case 'serious':
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke={smallRectStroke}>
            <rect height="1" rx=".5" width="2" x=".5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="9.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="13.5" y="7.5" />
          </g>
          <rect
            fill="#E45770"
            height="11"
            rx="1.5"
            stroke={darkMode ? '#F59AA9' : '#C62B49'}
            width="3"
            x="4.5"
            y="2.5"
          />
        </svg>
      )
    case 'moderate':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke={smallRectStroke}>
            <rect height="1" rx=".5" width="2" x=".5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="4.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="13.5" y="7.5" />
          </g>
          <rect
            x="8.5"
            y="2.5"
            width="3"
            height="11"
            rx="1.5"
            fill={darkMode ? '#DB7903' : '#EDBB4A'}
            stroke={darkMode ? '#F1E08F' : '#DB7903'}
          />
        </svg>
      )
    case 'minor':
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke={smallRectStroke}>
            <rect height="1" rx=".5" width="2" x="8.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x="4.5" y="7.5" />
            <rect height="1" rx=".5" width="2" x=".5" y="7.5" />
          </g>
          <rect
            fill={darkMode ? '#EDBB4A' : '#F1E08F'}
            height="11"
            rx="1.5"
            stroke={darkMode ? '#F1E08F' : '#EDBB4A'}
            width="3"
            x="12.5"
            y="2.5"
          />
        </svg>
      )
    default:
      return null
  }
}

export const IconWindowBrowser = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_5551_115930" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V6V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V6V3C16 1.34315 14.6569 0 13 0H3ZM2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V5H2V3ZM14 6H2V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V6ZM3.5 4C3.77614 4 4 3.77614 4 3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4ZM5.5 4C5.77614 4 6 3.77614 6 3.5C6 3.22386 5.77614 3 5.5 3C5.22386 3 5 3.22386 5 3.5C5 3.77614 5.22386 4 5.5 4ZM8 3.5C8 3.77614 7.77614 4 7.5 4C7.22386 4 7 3.77614 7 3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V6V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V6V3C16 1.34315 14.6569 0 13 0H3ZM2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V5H2V3ZM14 6H2V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V6ZM3.5 4C3.77614 4 4 3.77614 4 3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4ZM5.5 4C5.77614 4 6 3.77614 6 3.5C6 3.22386 5.77614 3 5.5 3C5.22386 3 5 3.22386 5 3.5C5 3.77614 5.22386 4 5.5 4ZM8 3.5C8 3.77614 7.77614 4 7.5 4C7.22386 4 7 3.77614 7 3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5Z"
      fill="#9095AD"
    />
    <path
      d="M14 5V6H15V5H14ZM2 5H1V6H2V5ZM14 6H15V5H14V6ZM2 6V5H1V6H2ZM1 3C1 1.89543 1.89543 1 3 1V-1C0.790861 -1 -1 0.790861 -1 3H1ZM1 6V3H-1V6H1ZM1 13V6H-1V13H1ZM3 15C1.89543 15 1 14.1046 1 13H-1C-1 15.2091 0.790861 17 3 17V15ZM13 15H3V17H13V15ZM15 13C15 14.1046 14.1046 15 13 15V17C15.2091 17 17 15.2091 17 13H15ZM15 6V13H17V6H15ZM15 3V6H17V3H15ZM13 1C14.1046 1 15 1.89543 15 3H17C17 0.790861 15.2091 -1 13 -1V1ZM3 1H13V-1H3V1ZM3 1C1.89543 1 1 1.89543 1 3H3V1ZM13 1H3V3H13V1ZM15 3C15 1.89543 14.1046 1 13 1V3H15ZM15 5V3H13V5H15ZM2 6H14V4H2V6ZM1 3V5H3V3H1ZM14 5H2V7H14V5ZM1 6V13H3V6H1ZM1 13C1 14.1046 1.89543 15 3 15V13H1ZM3 15H13V13H3V15ZM13 15C14.1046 15 15 14.1046 15 13H13V15ZM15 13V6H13V13H15ZM3 3.5C3 3.22386 3.22386 3 3.5 3V5C4.32843 5 5 4.32843 5 3.5H3ZM3.5 4C3.22386 4 3 3.77614 3 3.5H5C5 2.67157 4.32843 2 3.5 2V4ZM4 3.5C4 3.77614 3.77614 4 3.5 4V2C2.67157 2 2 2.67157 2 3.5H4ZM3.5 3C3.77614 3 4 3.22386 4 3.5H2C2 4.32843 2.67157 5 3.5 5V3ZM5 3.5C5 3.22386 5.22386 3 5.5 3V5C6.32843 5 7 4.32843 7 3.5H5ZM5.5 4C5.22386 4 5 3.77614 5 3.5H7C7 2.67157 6.32843 2 5.5 2V4ZM6 3.5C6 3.77614 5.77614 4 5.5 4V2C4.67157 2 4 2.67157 4 3.5H6ZM5.5 3C5.77614 3 6 3.22386 6 3.5H4C4 4.32843 4.67157 5 5.5 5V3ZM7.5 5C8.32843 5 9 4.32843 9 3.5H7C7 3.22386 7.22386 3 7.5 3V5ZM6 3.5C6 4.32843 6.67157 5 7.5 5V3C7.77614 3 8 3.22386 8 3.5H6ZM7.5 2C6.67157 2 6 2.67157 6 3.5H8C8 3.77614 7.77614 4 7.5 4V2ZM9 3.5C9 2.67157 8.32843 2 7.5 2V4C7.22386 4 7 3.77614 7 3.5H9Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_5551_115930)"
    />
  </svg>
)

export const IconStatusPassedCircleSolid = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="8" cy="8" r="7" fill="currentColor" />
    <path
      d="M10 6L7.5 10L6 8.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
