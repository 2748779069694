import _ from 'lodash'

export const BIGINT_INSTANCES_TABLES = [
  'instances',
  'test_result_analytics',
  'test_results_new',
  'test_result_attempts',
  'instance_details',
  'instance_stdout',
  'screencasts',
  'screenshots',
  'test_result_attempt_errors',
  'test_result_errors',
  'protocol_instance_raw',
  'protocol_test_data',
] as const

export const BIGINT_INSTANCES_TABLES_CAMEL = BIGINT_INSTANCES_TABLES.map(
  _.camelCase
)

/**
 * Used primarily to determine which table we should be targeting, based on the value of instance_id
 */
export function isBigint(val: number) {
  return val > 2147483647
}
