import moment from 'moment'

export function calculateUsagePeriods({
  currentUsagePeriodStartsAt,
  currentUsagePeriodEndsAt,
  organizationCreatedAt,
  periodUnit,
}: {
  currentUsagePeriodStartsAt: string
  currentUsagePeriodEndsAt: string
  organizationCreatedAt: string
  periodUnit?: string | null
}): {
  startTime: moment.Moment
  endTime: moment.Moment
}[] {
  if (!currentUsagePeriodStartsAt) return []

  // If no periodUnit default to month.
  periodUnit = periodUnit || 'month'

  // Calculate whether our input ends at the end of the month.
  const inputEndsAt = moment.utc(currentUsagePeriodEndsAt)
  const isEndOfMonth = inputEndsAt
    .clone()
    .milliseconds(0)
    .isSame(inputEndsAt.clone().endOf('month').milliseconds(0), 'second')

  const n = periodUnit === 'year' ? 2 : 6
  const usagePeriods: {
    startTime: moment.Moment
    endTime: moment.Moment
  }[] = []

  const startTime = moment.utc(currentUsagePeriodStartsAt)
  const endTime = moment.utc(currentUsagePeriodEndsAt)

  for (let i = 0; i < n; i++) {
    if (endTime.isBefore(organizationCreatedAt)) {
      break
    }

    const unit = periodUnit === 'year' ? 'year' : 'month'
    usagePeriods.push({
      startTime: startTime.clone(),

      // If our range lands on the end of the month then we must calculate the correct end of the month for each month.
      endTime: isEndOfMonth ? endTime.clone().endOf('month') : endTime.clone(),
    })

    startTime.subtract(1, unit)
    endTime.subtract(1, unit)
  }

  return usagePeriods
}
